import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import React, { Reducer, Suspense, useEffect, useReducer, useRef } from "react";
import { Logout } from "../RAFComponents/helpers/AppHelper";
import { IsNotNullOrWhiteSpace, IsNullOrWhiteSpace, isNotNullAndUndefined } from "../RAFComponents/helpers/utils";
import { UserInfoRow } from "../RAFComponents/models/Common/UserInfoRow";
import { initializeRAFDB } from "../WorkesioRepository";
import { initAppVersion } from "../WorkesioRepository/Version/VersionRepository";
import skyloading2 from "../assets/ACLoadingIcon.svg";
import ACLoadingPanel from "../components/shared/ACLoadingPanel";
import LoginSlider from "../components/shared/LoginSlider";
import { StorageKey } from "../constants/Common/Constants";
import { CanImpersonateUser, IsUserImpersonated, LogoutImpersonatedUser, RefreshUserStorage, getUserInfo } from "../helpers/ACutils";
// import { UserbackProvider, useUserback } from '@userback/react';
import RAFMessengerComponent from "../RAFModules/Common/RAFMessenger";
import connection from "../services/signalRService";


export const LoginUserContext = React.createContext<{ userInfo: UserInfoRow, canImpersonateUser: boolean; isImpersonatedUser: boolean; }>({ userInfo: null, canImpersonateUser: false, isImpersonatedUser: false });
export const LoginUserUpdateContext = React.createContext<{ updateUserInfo: (currentUser: UserInfoRow) => void; }>({ updateUserInfo: null });
declare const progressier: any;

interface Props {
    children: React.ReactNode;
}

interface IState {
    loading: boolean;
    userInfo: UserInfoRow;
    canImpersonateUser: boolean;
    isImpersonatedUser: boolean;
    error: string | number;
    message: string | null;
    clearCacheMessage: string | null;
}


const RAFLoginUserContent = ({ children }: Props) => {
    const { instance, inProgress } = useMsal();

    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            loading: true,
            userInfo: null,
            canImpersonateUser: false,
            isImpersonatedUser: false,
            error: null,
            message: null,
            clearCacheMessage: null
        }
    );

    const clientLogo = `${window.location.origin}/Content/img/workesio logo.png`;

    const logoutSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        //Logout(instance);

        IsUserImpersonated().then((isImpersonatedUser) => {
            if (isNotNullAndUndefined(isImpersonatedUser) && isImpersonatedUser === true) {
                LogoutImpersonatedUser();
            }
            else {
                Logout();


            }
        });
    };

    const renderStart = useRef(performance.now());

    useEffect(() => {
        refresh();
    }, [inProgress, state.userInfo, instance]);

    useEffect(() => {
        // const renderEnd = performance.now();
        // const renderTime = renderEnd - renderStart.current;
        // console.log(`RAFLoginUserContent Component rendered in ${renderTime} ms`);
    }, [state.loading]);

    const refresh = () => {
        if (!state.userInfo && inProgress === InteractionStatus.None) {
            getUserInfoRow();
        }
    };

    const shouldSmartlookTrackUser = (userInfoRow: UserInfoRow) => {
        //return user.role === 'admin' || user.id === 'specificUserId';
        return true;
    };

    const initSmartlook = (userInfoRow: UserInfoRow) => {
        if (window['smartlook']) {
            if (shouldSmartlookTrackUser(userInfoRow)) {
                window['smartlook']('init', 'cb8a3b4c3555f5a2c70b55c1a6899ca56d80d597', { region: 'eu' });
                window['smartlook']('identify', userInfoRow.UserUID, {
                    name: userInfoRow.DisplayName,
                    email: userInfoRow.Email,
                    tenantName: userInfoRow.TenantName,
                    // Additional attributes
                });
                window['smartlook']('start');
            } else {
                window['smartlook']('stop');
            }
        }
    };

    const initSentryUser = (userInfoRow: UserInfoRow) => {
        //if (window.location.hostname !== WorkESIOHosts.localhost) {
        import("@sentry/react").then(Sentry => {
            Sentry.setUser({
                id: userInfoRow.UserUID,
                email: userInfoRow.Email,
                username: userInfoRow.DisplayName,
                ip_address: "{{auto}}",
            });
        });
        //}
    };


    const getUserInfoRow = () => {
        //console.log("getUserInfoRow api call start");
        //console.time("getUserInfoRow time");
        setState({ loading: true });
        getUserInfo().then(async userInfoResponse => {
            //console.log("getUserInfoRow api call end", { userInfoResponse });
            //console.timeLog("getUserInfoRow time");
            const userInfoRow: UserInfoRow = userInfoResponse as UserInfoRow;
            if (isNotNullAndUndefined(userInfoRow) && isNotNullAndUndefined(userInfoRow.UserUID) && isNotNullAndUndefined(userInfoRow.Tenants) && userInfoRow.Tenants.length > 0) {
                try {
                    progressier.add({
                        id: userInfoRow.NotificationId
                    });
                } catch (error) {
                    console.log("Error in adding progressier user id", error);
                }

                const [canImpersonateUser, isImpersonatedUser] = await Promise.all([CanImpersonateUser(), IsUserImpersonated()]);

                instance["currentUser"] = userInfoRow;
                let currentTenantRow = window.localStorage.getItem(StorageKey.currentOrganisation);
                if (IsNullOrWhiteSpace(currentTenantRow)) {
                    if (!IsNullOrWhiteSpace(userInfoRow.TenantUID)) {
                        const objTenant = userInfoRow.Tenants.find(x => x.UID === userInfoRow.TenantUID);
                        if (isNotNullAndUndefined(objTenant)) {
                            window.localStorage.setItem(StorageKey.currentOrganisation, JSON.stringify(objTenant));
                        } else {
                            window.localStorage.setItem(StorageKey.currentOrganisation, JSON.stringify(userInfoRow.Tenants[0]));
                        }
                    } else {
                        window.localStorage.setItem(StorageKey.currentOrganisation, JSON.stringify(userInfoRow.Tenants[0]));
                    }
                }

                initSmartlook(userInfoRow);
                initSentryUser(userInfoRow);
                initializeRAFDB().then((db) => {
                    initAppVersion().then(() => {

                        // const versionCollection = db['version'];
                        // versionCollection.insert$.subscribe(changeEvent => {
                        //     console.log("versionCollection.insert$");
                        //     console.dir(changeEvent);
                        // });
                        // versionCollection.update$.subscribe(changeEvent => {
                        //     console.log("versionCollection.update$");
                        //     console.dir(changeEvent);
                        // });
                        // versionCollection.remove$.subscribe(changeEvent => {
                        //     console.log("versionCollection.remove$");
                        //     console.dir(changeEvent);
                        // });

                        // initializeRAFFB().then(() => {
                        setState({ loading: false, userInfo: userInfoRow, canImpersonateUser, isImpersonatedUser, error: null });
                        //console.log("getUserInfoRow initialization end");
                        //console.timeEnd("getUserInfoRow time");
                        // });
                    });
                });
            }
            else {
                if (typeof userInfoRow === "number" && userInfoRow === 403) {
                    // Handle 403 error here
                    console.log("getUserInfoRow api call end else userinfo is 403", userInfoRow);
                    window.localStorage.removeItem(StorageKey.currentOrganisation);
                    setState({ loading: false, userInfo: null, canImpersonateUser: false, isImpersonatedUser: false, error: userInfoRow });
                }
                else {
                    if (typeof userInfoRow === "number") {
                        console.log("getUserInfoRow api call end else userinfo is number", userInfoRow);
                        setState({ loading: false, userInfo: null, canImpersonateUser: false, isImpersonatedUser: false, error: userInfoRow });
                    }
                    else {
                        console.log("getUserInfoRow api call end else userinfo is not number", userInfoRow);
                        setState({ loading: false, userInfo: null, canImpersonateUser: false, isImpersonatedUser: false, error: "1" });
                    }
                }
                //console.timeEnd("getUserInfoRow time");
            }
        });
    };

    const updateUserInfo = (currentUser: UserInfoRow) => {
        instance["currentUser"] = currentUser;
        getUserInfoRow();
    };

    const handleUpdateClick = () => {
        RefreshUserStorage(true);
        //window.location.reload();
    };

    useEffect(() => {
        // if (isNotNullAndUndefined(state.userInfo) && IsNotNullOrWhiteSpace(state.userInfo.UserUID)) {
        //     connection.on("ReceiveVersionUpdate", (message) => {
        //         console.log("ReceiveVersionUpdate", message);
        //         setState({ message: message });

        //     });

        //     return () => {
        //         connection.off("ReceiveVersionUpdate");
        //     };
        // }

        if (isNotNullAndUndefined(state.userInfo) && IsNotNullOrWhiteSpace(state.userInfo.UserUID)) {
            connection.on("ReceiveClearCache", (message) => {
                console.log("ReceiveClearCache", message);
                //uncomment/comment below 2 lines to show message instead of auto refresh
                //setState({ clearCacheMessage: message });
                RefreshUserStorage(true);

            });

            return () => {
                connection.off("ReceiveClearCache");
            };
        }

    }, [state.userInfo]);
    return (
        <>
            {state.userInfo ? (
                // <UserbackProvider token="A-gT4eoeaLmlG5A7cGjkPj0Zjq5"
                //     options={{
                //         email: state.userInfo.Email,
                //         name: `${state.userInfo.DisplayName} ${state.userInfo.TenantName}`,
                //     }}
                // >
                <LoginUserUpdateContext.Provider
                    value={{
                        updateUserInfo: updateUserInfo
                    }}
                >
                    {state.message && (
                        <div className="notification" style={{ backgroundColor: 'yellow', padding: '10px', marginBottom: '20px', border: '1px solid #ccc' }}>
                            {state.message}
                        </div>
                    )}
                    {state.clearCacheMessage && (
                        <div className="notification" style={{ backgroundColor: 'yellow', padding: '10px', marginBottom: '20px', border: '1px solid #ccc' }}>
                            <p>Some configuration changes have been made. Please click "Update" to apply the changes.</p>
                            <button onClick={handleUpdateClick} style={{ padding: "8px 16px", cursor: "pointer" }}>
                                Update
                            </button>
                        </div>
                    )}
                    <LoginUserContext.Provider
                        value={{
                            userInfo: state.userInfo,
                            canImpersonateUser: state.canImpersonateUser,
                            isImpersonatedUser: state.isImpersonatedUser
                        }}
                    >

                        {children}
                        <RAFMessengerComponent />
                    </LoginUserContext.Provider>
                </LoginUserUpdateContext.Provider>
                // </UserbackProvider>
            ) : (
                // <UserbackProvider token="A-gT4eoeaLmlG5A7cGjkPj0Zjq5">
                <div className="h-100">
                    <div className="h-100 content panel p-0">
                        <div className="h-100 d-flex justify-content-lg-end justify-content-center">
                            <div className="right-section d-none d-lg-block min-vh-100">
                                <div className="h-100 position-relative">
                                    <Suspense fallback={<ACLoadingPanel loadingText="Loading..." />}>
                                        <LoginSlider />
                                    </Suspense>
                                </div>
                            </div>
                            <div className="left-section bg-white">
                                <div className="loginContainer overflow-auto d-flex align-items-center flex-column">
                                    <div className="w-100 formContainer d-flex flex-column align-items-center justify-content-center">
                                        {state.loading === true ? (
                                            <div>
                                                <div className="d-flex flex-column align-items-center" >
                                                    <img src={skyloading2} width="100px" alt="Loading" />
                                                    <span className="loadingText text-center">One moment<br />while we sign you in...</span>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="text-center">
                                                <img src={clientLogo} alt="Product Logo" className="clientlogoImage mb-3" />
                                                <div className="d-flex justify-content-center align-items-center pb-2">
                                                    <h5>Looks like your login is disabled or you do not have access to this site (Code: RAF{state.error})</h5>
                                                </div>
                                                <div className="d-flex justify-content-center align-items-center pb-2">
                                                    <form onSubmit={logoutSubmit} className="d-flex justify-content-center align-items-center flex-column w-100 me-2">
                                                        <ButtonComponent type="submit" cssClass='e-flat e-primary primary-btn me-2 w-100'>Continue</ButtonComponent>
                                                    </form>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                // </UserbackProvider>
            )}
        </>
    );
};

export default function RAFLoginUserContextProvider({ children }) {

    return (

        <RAFLoginUserContent>
            {children}
        </RAFLoginUserContent>
    );
}
