import React from "react";
import { IsNotNullOrWhiteSpace, IsNullOrWhiteSpace, isNotNullAndUndefined } from "./RAFComponents/helpers/utils";
import "./styles/ErrorBoundaryStyles.scss";
//import { withRouter } from "./router";
import { Constants } from "./constants/Common/Constants";
import RAFButtonComponent from "./RAFComponents/Navigation/RAFButtonComponent";
import * as Sentry from "@sentry/react";

export class ErrorThrower extends React.Component {
  render() {
    if (this.props.shouldThrow) {
      throw new Error('An error has occurred');
    } else {
      return <div>No errors here!</div>;
    }
  }
}

class ErrorBoundary1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    console.log('ErrorBoundary:' + JSON.stringify(error))
    // alert('ErrorBoundary:' + JSON.stringify(error));
    if (!IsNullOrWhiteSpace(error) && error.toString().includes('missing') && error.toString().includes('chunk.js')) {
      //window.location.href = '/home'
      window.location.href = '/'
    }
    else {
      // Catch errors in any components below and re-render with error message
      this.setState({
        error: error,
        errorInfo: errorInfo
      })
      // You can also log error messages to an error reporting service here
    }
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null, errorInfo: null,
      eventId: null
    };
  }

  // static getDerivedStateFromError(error) {
  //   // Update state so the next render will show the fallback UI.
  //   return { hasError: true };
  // }

  componentDidCatch(error, info) {
    // Handle the error (e.g., log it, show a custom message, etc.)
    console.error('Error caught:', error, info);
    if (!IsNullOrWhiteSpace(error) && error.toString().includes('missing') && error.toString().includes('chunk.js')) {
      //window.location.href = '/home'
      window.location.href = '/'
    }
    else {
      this.setState({
        hasError: true,
        error: error,
        errorInfo: info
      });
      // Sentry.withScope(scope => {
      //   scope.setExtras(errorInfo);
      //   const eventId = Sentry.captureException(error);
      //   this.setState({ eventId })
      // });
    }
  }

  handleReportClick = (event) => {
    // Gather relevant error information (e.g., error message, stack trace, user context)
    const errorData = {
      //errorData: {
      ErrorMessage: isNotNullAndUndefined(this.state.error) && IsNotNullOrWhiteSpace(JSON.stringify(this.state.error)) && JSON.stringify(this.state.error) !== '{}' ? JSON.stringify(this.state.error) : null,
      ErrorStackTrace: this.state.errorInfo,
      //errorMessage: this.state.error.message,
      //stackTrace: this.state.error.stack,
      // Add any other relevant data (user info, environment details, etc.)
      //}
    };

    console.log('Error data:', errorData);
    event.preventDefault();

    //Sentry.showReportDialog({ eventId: this.state.eventId })

    // // Make an API call to report the error
    fetch(`${Constants.baseAPIUrl}User/Error`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(errorData),
    })
      .then(response => {
        // Handle the response (e.g., show a success message)
        console.log('Error reported:', response);
        if (response.ok) {
          alert('Thank you for reporting the issue. We appreciate your help in improving our application.');
        }
      })
      .catch(error => {
        // Handle any errors related to the API call
        console.error('Error reporting error:', error);
      });
  };

  handleReturnClick = () => {
    // Navigate back to the previous page
    window.history.back();
  };

  handleReloadClick = () => {
    // Navigate back to the previous page
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className="rafErrorBoundary error-container">
          <div className="error-icon">!</div>
          <div className="error-heading">Something went wrong</div>
          <div className="error-subtext">
            There was a problem processing the request. Please try again.
          </div>
          <a
            href="#"
            className="report-link"
            onClick={this.handleReportClick}
          >
            Report an issue
          </a>
          <div className="justify-content-center align-items-center">
            <div className="justify-content-center align-items-center flex-column px-2 ">
              <RAFButtonComponent btnContent="Reload this page"
                onClick={this.handleReloadClick}
                isPrimary
                className="w-75"
              ></RAFButtonComponent>
              {/* <button className="e-flat e-primary custom-button-md primary-btn me-2 w-75 return-button" onClick={this.handleReloadClick}>Reload this page</button> */}
            </div>
            <div className="justify-content-center align-items-center py-3">
              OR
            </div>
            <div className="justify-content-center align-items-center flex-column px-2 ">
              <RAFButtonComponent btnContent="Return to last page"
                onClick={this.handleReturnClick}
                isPrimary
                className="w-75 e-outline"
              ></RAFButtonComponent>
              {/* <button className="e-flat custom-button-md e-outline me-2 w-75 return-button" onClick={this.handleReturnClick}>Return to last page</button> */}
            </div>
          </div>
        </div>
      );
    }

    // Render the child components normally
    return this.props.children;
  }
}

//export default withRouter(ErrorBoundary);
export default ErrorBoundary;